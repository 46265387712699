import { useState } from "react"
import styled from "styled-components"

import { OrganizationRolePicker } from "src/components/Organizations/Organization/OrganizationRolePicker"
import { useAppData } from "src/context/useAppData"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useDeleteMember,
  usePatchMember,
} from "src/data/organizations/queries/memberQueries"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import {
  IOrganizationMember,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { RemoveMemberDialog } from "./RemoveMemberDialog"

export function MemberTable({
  orgId,
  members,
  loading,
}: {
  orgId: string
  members?: IOrganizationMember[]
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const fetchOrganizations = useFetchOrganizations()
  const patchMember = usePatchMember()
  const deleteMember = useDeleteMember()
  const [deleteMemberData, setDeleteMemberData] = useState<null | {
    memberId: string
    deleteSelf?: boolean
    displayName: string
  }>(null)

  const { user_id: currentUserId } = useGetUser()
  const { orgAccessLogic } = useOrganization()
  const isOrgAdmin = orgAccessLogic.hasAdminAccess

  const { setActiveOrgId } = useAppData()

  function onDelete(memberId: string, deleteSelf?: boolean) {
    deleteMember.mutate(
      { orgId, memberId },
      {
        onSuccess: () => {
          setDeleteMemberData(null)
          if (deleteSelf) {
            setActiveOrgId(null)
            navigate(Routes.Dashboard.location())
          }
        },
      }
    )
  }

  function onChange(memberId: string, body: Partial<IOrganizationMember>) {
    patchMember.mutate({ orgId, memberId, body })
  }

  function getRows() {
    return members?.map((member, i) => (
      <MemberRow
        key={member.member_id}
        member={member}
        isCurrentUser={member.user_id === currentUserId}
        isOrgAdmin={isOrgAdmin}
        onDelete={(memberId, deleteSelf) =>
          setDeleteMemberData({
            memberId,
            deleteSelf,
            displayName: member.name,
          })
        }
        onChange={onChange}
        loading={
          patchMember.isLoading ||
          deleteMember.isLoading ||
          fetchOrganizations.isLoading ||
          loading
        }
      />
    ))
  }

  return (
    <div>
      <GridTable
        header={[
          <div key={"name"}>{t(langKeys.name)}</div>,
          <div key={"role-title"}>
            {t(langKeys.organizations_organization_role_title)}
          </div>,
          <div key={"misc"}></div>,
        ]}
        rows={getRows() ?? []}
        templateColumns="2fr auto auto"
        useFallbackResponsiveMode
      />

      <RemoveMemberDialog
        deleteMemberData={deleteMemberData}
        loading={deleteMember.isLoading}
        onClose={() => setDeleteMemberData(null)}
        onConfirm={() => {
          if (!deleteMemberData) {
            return
          }
          onDelete(deleteMemberData.memberId, deleteMemberData.deleteSelf)
        }}
      />

      <DebugBox data={members} minimized />
    </div>
  )
}

function MemberRow({
  member,
  isOrgAdmin,
  isCurrentUser,
  loading,
  onChange,
  onDelete,
}: {
  member: IOrganizationMember
  isOrgAdmin: boolean
  isCurrentUser: boolean
  loading?: boolean
  onChange: (memberId: string, body: Partial<IOrganizationMember>) => void
  onDelete: (memberId: string, deleteSelf?: boolean) => void
}) {
  const { t, langKeys } = useTranslate()

  const isOrgOwner = member.role === "owner"

  async function setRole(role: TOrganizationRole) {
    onChange(member.member_id, { role })
  }

  return (
    <>
      <div>
        <MText variant="subtitle">{member.name}</MText>
        <MText variant="bodyS" color="secondary">
          {member.email}
        </MText>
      </div>

      <div>
        <RoleColumnBox
          hidden={
            // If the BE doesn't have a role set for the user, we won't be able
            // to change it either; hence let's just hide the dropdown if that's
            // the case:
            !member.role
          }
        >
          <OrganizationRolePicker
            inline
            value={member.role}
            disabled={
              loading ||
              isCurrentUser || // user can't change their own role
              isOrgOwner || // we may never change the role of the owner
              !isOrgAdmin // admin access is required to change roles
            }
            onChange={setRole}
          />
        </RoleColumnBox>
      </div>

      <div style={{ paddingLeft: spacing.XL3 }}>
        {(isCurrentUser && (
          <MoreButton
            disabled={loading || isOrgOwner}
            onDelete={() => onDelete(member.member_id, isCurrentUser)}
            deleteLabel={t(
              langKeys.organizations_leave_team_confirm_button_label
            )}
            showDeleteIcon={false}
          />
        )) || (
          <MoreButton
            disabled={loading || isOrgOwner || !isOrgAdmin}
            onDelete={() => onDelete(member.member_id, isCurrentUser)}
          />
        )}
      </div>
    </>
  )
}

const RoleColumnBox = styled.div`
  display: flex;
  align-items: start;
`
